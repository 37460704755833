<template>
    <h6 v-if="blankMessage && iframeRef?.currentSrc == null">
        {{ blankMessage }}
    </h6>
    <template v-if="$slots.detailTab">
        <slot v-if="iframeEnabled" name="detailTab"></slot>
    </template>
    <OIframe v-else :ref="setIframeRef" :src="iframeSrc" @load="handleLoaded" :enabled="iframeEnabled" />
</template>

<script setup lang="ts">
import OIframe from './Iframes.Iframe.vue';
import { ref } from 'vue';

const props = defineProps<{
    blankMessage?: string,
    iframeSrc?: string;
    detailTabTitle: string;
    iframeEnabled?: boolean;
}>();

const iframeRef = ref<InstanceType<typeof OIframe>>();

function setIframeRef(pCmp: InstanceType<typeof OIframe>) {
    iframeRef.value = pCmp;
}

function handleLoaded(e: Event) {
    if (props.iframeSrc) {
        // props.menuTabs.handleIframeLoaded(props.iframeSrc, e);
    }
}

</script>